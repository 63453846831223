import { studentMapper } from "./studentsMapper"

export function classMapper(class_data) {
  if (class_data) {
    return {
      students: class_data.students
        ? class_data.students.map((student) => studentMapper(student))
        : undefined,
      id: class_data._id,
      name: class_data.name,
      grade: {
        id: class_data.grade._id,
        name: class_data.grade.name,
        order: class_data.grade.order,
      },
      assessmentsTime: class_data.exam_frecuency,
      daysSelected: class_data.days,
      role: class_data.role_in_class?.name,
      status: class_data.status, // "OPEN" | "CLOSED"
      default_lang: class_data.default_lang,
    }
  } else {
    return null
  }
}

export function classesMapper(classes) {
  return classes.classes
    .filter((c) => c.status === "OPEN")
    .map((c) => classMapper(c))
}

export function rolesMapper(data) {
  return data.map((role) => {
    return {
      id: role._id,
      name: role.name,
    }
  })
}
