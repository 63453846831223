import React, { useEffect, useRef, useState } from "react"

const NavigationView = ({
  timelineLength,
  timelinePosition,
  onSliderPositionChange,
  onNavigatePrevious,
  previousBtnEnabled,
  onNavigateNext,
  nextBtnEnabled,
}) => {
  const [timeoutHandle, setTimeoutHandle] = useState(undefined)
  const [sliderPosition, setSliderPosition] = useState(0)
  const isSliderBarChanging = useRef(false)

  const prevButtonDisabled = sliderPosition === 0
  const nextButtonDisabled = sliderPosition >= timelineLength - 2

  useEffect(() => {
    if (isSliderBarChanging.current) {
      isSliderBarChanging.current = false
      return
    }

    if (timelinePosition !== undefined && timelinePosition !== sliderPosition) {
      setSliderPosition(timelinePosition)
    }
    // eslint-disable-next-line
  }, [timelinePosition])

  const handleSliderChange = (e) => {
    if (timeoutHandle !== undefined) {
      window.clearTimeout(timeoutHandle)
    }

    isSliderBarChanging.current = true

    const newProgress = parseInt(e.target.value)
  
    setSliderPosition(newProgress)

    setTimeoutHandle(
      window.setTimeout(() => {
        onSliderPositionChange(newProgress)
      }, 500)
    )
  }

  return (
    <div
      className="d-flex flex-row justify-content-between align-items-center mx-3"
      style={{ marginBottom: 20 }}
    >
      <div
        className={`bg-${
          prevButtonDisabled ? "secondary" : "success"
        } rounded-pill align-self-start d-flex justify-content-center`}
        style={{
          cursor: prevButtonDisabled ? "default" : "pointer",
          padding: "5px 15px",
          minWidth: "100px",
        }}
        onClick={onNavigatePrevious}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: "#fff",
            textAlign: "center",
          }}
        >
          {"Previous"}
        </span>
      </div>
      <div className="d-flex flex-column align-items-center w-100 position-relative">
        <>
          {/* <div>{calculateProgressPercentage(sliderPosition)} %</div> */}
          {/* TODO: revisar aca por que no muestra bien el indice de pagina */}
          {timelineLength ? (
            <div className="position-absolute" style={{ top: "-1rem" }}>
              Page {sliderPosition + 1} of {timelineLength}
            </div>
          ) : null}
          <nav
            className="px-3 w-100"
            style={{
              display: "flex",
              flexGrow: 1,
            }}
          >
            <input
              className="book-navigator"
              type="range"
              id="slider"
              style={{
                display: "flex",
                flexGrow: 1,
                width: "100%",
                backgroundColor: "transparent",
              }}
              min={0}
              max={timelineLength - 1}
              value={sliderPosition}
              onChange={handleSliderChange}
            />
          </nav>
        </>
      </div>
      <div
        className={`bg-${
          nextButtonDisabled ? "secondary" : "success"
        } rounded-pill align-self-start d-flex justify-content-center`}
        style={{
          cursor: nextButtonDisabled ? "default" : "pointer",
          padding: "5px 15px",
          minWidth: "100px",
        }}
        onClick={onNavigateNext}
      >
        <span
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: "#fff",
            textAlign: "center",
          }}
        >
          {"Next"}
        </span>
      </div>
    </div>
  )
}
export default NavigationView
