/*
static async loadPublicationByFile(file: File): Promise<ReaderApi>
Asynchronuously setups a ReadingSystemEngine, ReaderView. Adds a StackRenderer, FlipbookRenderer. Loads the publication and calls ReaderView.setReaderDocuments().
Note: the constructor is private.

static async loadPublicationByUrl(url: string): Promise<ReaderApi>
Same as above but loads the publication from an URL.

renderToElement(element: HTMLElement | null): void
Calls ReaderView.renderTo(element)
Calls ReaderView.goToStart() if the ReaderView has ReaderDocuments assigned to it.

next(): void
Calls ReaderView.next() if ReaderView.canPerformNext() returns true.

previous(): void
Calls ReaderView.previous() if ReaderView.canPerformPrevious() returns true.

addReadingProgressCallback(callback: (IReadingProgressData) => void)
Add callback to track  reading progress changes. Uses ContentPositionTimeline underneath.
A newly added callback gets called immediately with the latest value. (Or as soon as possible if the timeline is still being created).

removeReadingProgressCallback(callback: (IReadingProgressData) => void)
Removes a callback previously added with addReadingProgressCallback.

navigateToReadingProgressValue(progress: number): void
Navigates to the position in the publication closest matching the reading progress value
 */

import { MediaType } from "@colibrio/colibrio-reader-framework/colibrio-core-io-base"
import { EpubOcfResourceProvider } from "@colibrio/colibrio-reader-framework/colibrio-core-publication-epub"
import {
  EpubFormatAdapter,
  EpubRemoteResourcePolicyType,
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub"
import {
  ContentPositionTimelineUnit,
  NavigationCollectionType,
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base"
import { PdfPublication } from "@colibrio/colibrio-reader-framework/colibrio-core-publication-pdf"
import { ReadingSystemEngine } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine"
import { PdfFormatAdapter } from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf"
import {
  FlipBookRenderer,
  // ScrollRendererScrollbarPosition,
  // SingleDocumentScrollRenderer,
  //   StackRenderer,
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-renderer"

import { HTTPRandomAccessDataSource } from "./utils/io/http/HTTPRandomAccessDataSource"
import { HttpResourceProvider } from "./utils/io/http/HttpResourceProvider"

import { bookshelf } from "./model/Bookshelf"

import { LengthUnit } from "@colibrio/colibrio-reader-framework/colibrio-core-base"
import { PublicationType } from "@colibrio/colibrio-reader-framework/colibrio-core-publication-base"

const FLIPBOOK = "flipbook"

export class ReadingSystemAPI {
  static instance = null

  constructor(licenseApiKey) {
    ReadingSystemAPI.instance = this
    //Setup reading system and reading views
    this._colibrioReadingSystem = new ReadingSystemEngine({
      licenseApiKey: licenseApiKey,
    })
    this._colibrioReadingSystem.addFormatAdapter(new EpubFormatAdapter())
    this._colibrioReadingSystem.addFormatAdapter(new PdfFormatAdapter())
    this._colibrioReaderView = this._colibrioReadingSystem.createReaderView({
      transformManagerOptions: {
        removeTransformOnNavigation: true,
        maxPanOffsetHorizontal: {
          unit: LengthUnit.PERCENT,
          value: 50,
        },
        maxPanOffsetVertical: {
          unit: LengthUnit.PERCENT,
          value: 50,
        },
        maxScaleFactor: 2,
      },
      gestureOptions: {
        panZoom: {
          allowSinglePointerPan: true,
          pointerTypes: {
            pen: true,
            mouse: true,
            touch: true,
          },
          removeTransformOnZoomOutGesture: false,
        },
      },
    })
    this._colibrioReaderView.addRenderer(
      new FlipBookRenderer({
        name: FLIPBOOK,
      })
    )

    this._bookmarkAnnotationLayer = null
    this._highlightAnnotationLayer = null
    this._colibrioContentPositionTimeline = null
    this._progressCallback = null
    this._selectionChangeCallback = null
    this._pointerUpCallback = null
    this._pointerDownCallback = null
    this._keyDownCallback = null
    this._keyUpCallback = null
    this._navigationIntentCallback = null

    this.readerPublication = null
    this.readerPublicationNavigation = null
    this.ebook = null

    this.setUpAnnotationLayers()
    this.setUpEventHandlers()
  }

  setUpAnnotationLayers() {
    this._bookmarkAnnotationLayer =
      this._colibrioReaderView.createAnnotationLayer("bookmarks")
    this._bookmarkAnnotationLayer.setLayerOptions({
      layerClassName: "bookmarks-layer",
      annotationInputEngineEventsEnabled: true,
    })
    this._bookmarkAnnotationLayer.setDefaultAnnotationOptions({
      positionStyle: {
        width: "16px",
        height: "16px",
        "background-color": "black",
        "border-radius": "50%",
      },
      containerClassName: "bookmark-container",
      containerStyle: {
        width: "100%",
        height: "8px",
        right: "0",
      },
    })

    this._bookmarkAnnotationLayer.addEngineEventListener(
      "annotationClick",
      (event) => {
        this.removeBookmarkAnnotation(event.annotation.getCustomData().id)
      }
    )

    this._highlightAnnotationLayer =
      this._colibrioReaderView.createAnnotationLayer("highlights")
    this._highlightAnnotationLayer.setDefaultAnnotationOptions({
      rangeClassName: "highlights-layer",
      rangeStyle: {
        "background-color": "yellow",
        padding: "2px",
        "margin-top": "-2px",
        "margin-left": "-2px",
        "border-radius": "4px",
      },
    })
    this._highlightAnnotationLayer.setLayerOptions({
      annotationInputEngineEventsEnabled: true,
      layerStyle: { "mix-blend-mode": "multiply" },
    })
    this._highlightAnnotationLayer.addEngineEventListener(
      "annotationClick",
      (event) => {
        this.removeHighlightAnnotation(event.annotation.getCustomData().id)
      }
    )
  }

  setUpEventHandlers() {
    this._colibrioReaderView.addEngineEventListener("visibleRangeChanged", () =>
      this.progressUpdated()
    )
    this._colibrioReaderView.addEngineEventListener(
      "selectionChanged",
      (event) => this.selectionChanged(event)
    )
    this._colibrioReaderView.addEngineEventListener(
      "navigationIntent",
      (event) => this.navigationIntent(event)
    )
    this._colibrioReaderView.addEngineEventListener("pointerdown", (event) =>
      this.pointerDown(event)
    )
    this._colibrioReaderView.addEngineEventListener("pointerup", (event) =>
      this.pointerUp(event)
    )
    this._colibrioReaderView.addEngineEventListener("keyup", (event) =>
      this.keyUp(event)
    )
    this._colibrioReaderView.addEngineEventListener("keydown", (event) =>
      this.keyDown(event)
    )
  }

  setSelectionChangeCallback(callback) {
    this._selectionChangeCallback = callback
  }

  setReadingProgressCallback = (callback) => {
    this._progressCallback = callback
  }

  setPointerUpCallback(callback) {
    this._pointerUpCallback = callback
  }

  setPointerDownCallback(callback) {
    this._pointerDownCallback = callback
  }

  setKeyDownCallback(callback) {
    this._keyDownCallback = callback
  }

  setKeyUpCallback(callback) {
    this._keyUpCallback = callback
  }

  setNavigationIntentCallback(callback) {
    this._navigationIntentCallback = callback
  }

  get readerView() {
    return this._colibrioReaderView
  }

  get isNavigating() {
    return this._colibrioReaderView.isNavigating()
  }

  get canPerformNext() {
    return this._colibrioReaderView.canPerformNext()
  }

  get canPerformPrevious() {
    return this._colibrioReaderView.canPerformPrevious()
  }

  get isAtStart() {
    return this._colibrioReaderView.isAtStart()
  }

  get isAtEnd() {
    return this._colibrioReaderView.isAtEnd()
  }

  get readingPosition() {
    return this._colibrioReaderView.getReadingPosition()
  }

  get contentPositionTimelineLength() {
    return this._colibrioContentPositionTimeline?.getLength()
  }

  get navigationDocument() {
    return this.readerPublicationNavigation
  }

  static createInstance(licenseApiKey) {
    if (ReadingSystemAPI.instance) {
      return ReadingSystemAPI.instance
    }

    return new ReadingSystemAPI(licenseApiKey)
  }

  fetchContentLocationFromContentPositionTimelinePosition = (position) => {
    return Promise.resolve(
      this._colibrioContentPositionTimeline?.fetchContentLocation(position)
    )
  }

  loadPublicationByFile = async (file) => {
    const readerPublication = await this.loadPublicationFromFile(
      this._colibrioReadingSystem,
      file
    )
    this.setPublication(readerPublication)
    const contentPositionTimeline = await this.createTimeline(readerPublication)
    this.setContentPositionTimeline(contentPositionTimeline)

    const publicationUri = `file://bookshelf/${file.name}`

    const ebook = await this.maybeStoreEbookDataForPublication(
      publicationUri,
      readerPublication
    )

    this.readerPublication = readerPublication
    this.readerPublicationNavigation =
      await readerPublication.fetchPublicationNavigation()
    this.ebook = ebook

    window.readingSystemAPI = this

    return ebook
  }

  loadPublicationByUrl = async (url) => {
    const readerPublication = await this.loadPublicationFromUrl(
      this._colibrioReadingSystem,
      url
    )
    this.setPublication(readerPublication)
    const contentPositionTimeline = await this.createTimeline(readerPublication)
    this.setContentPositionTimeline(contentPositionTimeline)

    let ebook = await this.maybeStoreEbookDataForPublication(
      url,
      readerPublication
    )

    this.readerPublication = readerPublication
    this.readerPublicationNavigation =
      await readerPublication.fetchPublicationNavigation()
    this.ebook = ebook

    return readerPublication
  }

  setContentPositionTimeline(value) {
    this._colibrioContentPositionTimeline = value
  }

  async onAfterRendered() {
    const highlights = await this.ebook?.fetchHighlights()
    const bookmarks = await this.ebook?.fetchBookmarks()
    const readingPositions = await this.ebook?.fetchReadingPositionHistory()

    if (highlights) {
      for (let highlight of highlights) {
        this._highlightAnnotationLayer.createAnnotation(
          highlight.locator,
          highlight
        )
      }
    }

    if (bookmarks) {
      for (let bookmark of bookmarks) {
        this._bookmarkAnnotationLayer.createAnnotation(
          bookmark.locator,
          bookmark
        )
      }
    }

    if (readingPositions && readingPositions.length > 0) {
      this.goto(readingPositions[0].locator).catch(console.warn)
    } else {
      this.gotoStart().catch(console.warn)
    }
  }

  renderToElement = (element) => {
    if (this && element) {
      this._colibrioReaderView.renderTo(element)
      this.onAfterRendered().catch(console.warn)
    }
  }

  next = () => {
    if (!this._colibrioReaderView.canPerformNext()) {
      console.warn(
        "ReadingSystemAPI.goto(), unable to perform next navigation at the moment."
      )
      return
    }
    this._colibrioReaderView
      .next()
      .catch(() => console.error("Failed to navigate next"))
  }

  previous = () => {
    if (!this._colibrioReaderView.canPerformPrevious()) {
      console.warn(
        "ReadingSystemAPI.goto(), unable to perform previous navigation at the moment."
      )
      return
    }
    this._colibrioReaderView
      .previous()
      .catch(() => console.error("Failed to navigate previous"))
  }

  goto = async (locator, focus = true) => {
    if (!this._colibrioReaderView.canPerformGoTo()) {
      console.warn(
        "ReadingSystemAPI.goto(), unable to perform goto navigation at the moment."
      )
      return
    }

    await this._colibrioReaderView.goTo(locator).catch(console.warn)
    if (focus) {
      this._colibrioReaderView
        .focusOnReadingPosition({
          focusOnPageBodyElement: false,
          focusOnPageContainer: true,
          focusNearContentLocation: true,
        })
        .catch(console.warn)
    }
  }

  gotoStart = async () => {
    return this._colibrioReaderView.goToStart()
  }

  navigateToReadingProgressValue = (progress) => {
    if (this._colibrioContentPositionTimeline) {
      this._colibrioContentPositionTimeline
        .fetchContentLocation(progress)
        .then((location) =>
          this._colibrioReaderView.goTo(location, {
            setReadingPositionToVisibleRangeStart: true,
          })
        )
        .catch(() => console.warn("Navigation failed"))
    }
  }

  refreshReaderView = () => {
    this._colibrioReaderView.refresh(false)
  }

  destroy = () => {
    this._colibrioReadingSystem.destroy()
    this.setContentPositionTimeline(null)
    this.setReadingProgressCallback(null)
  }

  addBookmarkAnnotation = async (locator) => {
    const navigationItems = await this.fetchNearestNavigationItemsForLocator(
      locator
    )
    let sectionTitle
    if (navigationItems && navigationItems.length > 0) {
      sectionTitle = navigationItems[0].getTextContent()
    }
    const bookmarkData = await this.ebook?.addBookmark(locator, sectionTitle)
    this._bookmarkAnnotationLayer.createAnnotation(locator, bookmarkData)
  }

  removeBookmarkAnnotation = (id) => {
    const annotationToRemove = this._bookmarkAnnotationLayer
      ?.getAnnotations()
      .find((item) => item.getCustomData().id === id)
    if (!annotationToRemove) {
      console.log(
        `ReadingSystemAPI.removeBookmarkAnnotation(), bookmark with id ${id} does not exist.`
      )
      return
    }
    this._bookmarkAnnotationLayer.destroyAnnotation(annotationToRemove)
    this.ebook.removeBookmark(id)
  }

  addHighlightAnnotation = async (locator, highlightColor, note) => {
    const navigationItems = await this.fetchNearestNavigationItemsForLocator(
      locator
    )
    let sectionTitle
    if (navigationItems && navigationItems.length > 0) {
      sectionTitle = navigationItems[0].getTextContent()
    }

    const highlightData = await this.ebook?.addHighlight(
      locator,
      highlightColor,
      note,
      sectionTitle
    )
    this._highlightAnnotationLayer.createAnnotation(locator, highlightData)
  }

  removeHighlightAnnotation = (id) => {
    const annotationToRemove = this._highlightAnnotationLayer
      ?.getAnnotations()
      .find((item) => item.getCustomData().id === id)
    if (!annotationToRemove) {
      console.log(
        `ReadingSystemAPI.removeHighlightAnnotation(), highlight with id ${id} does not exist.`
      )
      return
    }
    this._highlightAnnotationLayer.destroyAnnotation(annotationToRemove)
    this.ebook.removeHighlight(id)
  }

  updateHighlightAnnotation = (id, locator, highlightColor, note) => {
    if (this.ebook) {
      this.ebook
        .updateHighlight(id, locator, highlightColor, note)
        .catch(console.warn)
    }
  }

  getTableOfContents() {
    const tocCollection = this.readerPublicationNavigation
      ?.getNavigationCollections()
      .filter((collection) => {
        return collection.getType() === NavigationCollectionType.TOC
      })

    if (!tocCollection) {
      console.log(
        "ReadingSystemAPI.fetchTableOfContents(), Unable to find collection"
      )
      return []
    }

    return tocCollection.flatMap((item) => item.getChildren())
  }

  fetchContentLocationForLocator = async (locator) => {
    return this.readerPublication?.fetchContentLocation(locator)
  }

  fetchNearestNavigationItemsForLocator = async (locator) => {
    const contentLocation = await this.fetchContentLocationForLocator(locator)
    if (contentLocation) {
      const navigationItemRefs =
        await contentLocation.fetchNavigationItemReferences({
          greedy: true,
          collectionTypes: [NavigationCollectionType.TOC],
        })
      return navigationItemRefs.getItemsInRange().map((item) => {
        return item.getNavigationItem()
      })
    }
  }

  async maybeStoreEbookDataForPublication(uri, readerPublication) {
    const hashSignature = readerPublication
      .getSourcePublication()
      .getHashSignature()
    let bookshelfEbookItem = await bookshelf.fetchEbookByHash(hashSignature)
    if (bookshelfEbookItem) {
      return Promise.resolve(bookshelfEbookItem)
    }

    const metadata = readerPublication
      .getSourcePublication()
      .getMetadata()
      .getAll()
    const title =
      readerPublication.getSourcePublication().getMetadata().getTitles()[0]
        ?.content?.value || "Unknown"
    let author = "Unknown"
    const authorMetadataItem = metadata.find((item) => {
      return item.property.name === "author" || item.property.name === "creator"
    })

    if (authorMetadataItem) {
      author = authorMetadataItem.content.value
    }

    const ebookData = {
      uri,
      metadata,
      title,
      author,
      hashSignature: readerPublication
        .getSourcePublication()
        .getHashSignature(),
      dateCreated: Date.now(),
      dateLastUpdated: Date.now(),
    }

    return bookshelf.addEbook(ebookData)
  }

  selectionChanged(event) {
    if (this._selectionChangeCallback) {
      this._selectionChangeCallback(event)
    }
  }

  pointerDown(event) {
    if (this._pointerDownCallback) {
      this._pointerDownCallback(event)
    }
  }

  pointerUp(event) {
    if (this._pointerUpCallback) {
      this._pointerUpCallback(event)
    }
  }

  keyDown(event) {
    if (this._keyDownCallback) {
      this._keyDownCallback(event)
    }
  }

  keyUp(event) {
    if (this._keyUpCallback) {
      this._keyUpCallback(event)
    }
  }

  navigationIntent(event) {
    if (this._navigationIntentCallback) {
      this._navigationIntentCallback(event)
    }
  }

  async progressUpdated() {
    if (this._colibrioContentPositionTimeline && this._progressCallback) {
      //   const timelineLength = this._colibrioContentPositionTimeline.getLength()
      const readingPosition = this._colibrioReaderView.getVisibleRange()
      if (readingPosition) {
        let range =
          await this._colibrioContentPositionTimeline.fetchTimelineRange(
            readingPosition
          )
        const readingPositionData = await this.ebook?.addReadingPosition(
          readingPosition.collapseToStart().getLocator(),
          range.start
        )

        if (!readingPositionData) {
          console.warn(
            "ReadingSystemAPI.progressUpdated(), Unable to get IReadingPositionData"
          )
          return
        }

        this._progressCallback(readingPositionData)
      }
    }
  }

  async createTimeline(readerPublication) {
    if (
      this.isEpub(readerPublication) &&
      this.hasTimelineUnit(readerPublication, ContentPositionTimelineUnit.PAGES)
    ) {
      const timelineOptions = {
        unit: ContentPositionTimelineUnit.PAGES,
      }
      const epubPublication = readerPublication
      return epubPublication.createContentPositionTimeline(
        readerPublication.getSpine(),
        timelineOptions
      )
    } else {
      const pdfPublication = readerPublication
      return pdfPublication.createContentPositionTimeline(
        readerPublication.getSpine()
      )
    }
  }

  isEpub(readerPublication) {
    return (
      readerPublication.getSourcePublication().getType() ===
      PublicationType.EPUB
    )
  }

  hasTimelineUnit(readerPublication, unit) {
    return readerPublication
      .getAvailableContentPositionTimelineUnits()
      .includes(unit)
  }

  static getEpubPublicationFromFile = (file) => {
    return EpubOcfResourceProvider.createFromBlob(file).then(
      (epubOcfResourceProvider) => {
        let publication = epubOcfResourceProvider.getDefaultPublication()
        if (!publication) {
          throw new Error("The file did not contain any EPUB publication")
        }
        return publication
      }
    )
  }

  static getEpubPublicationFromUrl = async (url) => {
    if (url.endsWith(".epub")) {
      return HTTPRandomAccessDataSource.create(url)
        .then((httpRandomAccessDataSource) => {
          return EpubOcfResourceProvider.createFromRandomAccessDataSource(
            httpRandomAccessDataSource
          )
        })
        .then((epubOcfResourceProvider) => {
          let publication = epubOcfResourceProvider.getDefaultPublication()
          if (!publication) {
            throw new Error("The file did not contain any EPUB publication")
          }
          return publication
        })
    } else {
      const publicationUrl = new URL(url)
      const httpResourceProvider = new HttpResourceProvider(publicationUrl)
      return EpubOcfResourceProvider.createFromBackingResourceProvider(
        httpResourceProvider,
        publicationUrl.toString()
      ).then((epubOcfResourceProvider) => {
        let publication = epubOcfResourceProvider.getDefaultPublication()
        if (!publication) {
          throw new Error("The URL did not contain any EPUB publication")
        }
        return publication
      })
    }
  }

  static getPdfPublicationFromFile = (file) => {
    return PdfPublication.createFromBlob(file)
  }

  static getPdfPublicationFromUrl = (url) => {
    let publicationOptions = {
      enableDeterministicChunkRequests: true,
    }
    return HTTPRandomAccessDataSource.create(url).then(
      (httpRandomAccessDataSource) =>
        PdfPublication.createFromRandomAccessDataSource(
          httpRandomAccessDataSource,
          publicationOptions
        )
    )
  }

  setPublication(publication) {
    this._colibrioReaderView.setReaderDocuments(publication.getSpine())
    return publication
  }

  loadPublicationFromFile(readingSystem, file) {
    switch (file.type) {
      case MediaType.APPLICATION_EPUB_ZIP:
        return ReadingSystemAPI.getEpubPublicationFromFile(file).then(
          (publication) => {
            const readerPublicationOptions = {
              contentProcessingOptions: {
                skipUnusedImagesInScriptedPublicationCss: true,
              },
              enableMediaStreaming: true,
              remoteResourcesNonScriptedDocumentsOptions: {
                policyType: EpubRemoteResourcePolicyType.ALLOW_ALL,
              },
            }
            const readingSessionOptions = {
              publicationToken: "n/a",
              userToken: "n/a",
            }
            return readingSystem.loadPublication(
              publication,
              readerPublicationOptions,
              readingSessionOptions
            )
          }
        )

      case MediaType.APPLICATION_PDF:
        return ReadingSystemAPI.getPdfPublicationFromFile(file).then(
          (publication) => {
            const readerPublicationOptions = {}
            const readingSessionOptions = {
              publicationToken: "n/a",
              userToken: "n/a",
            }
            return readingSystem.loadPublication(
              publication,
              readerPublicationOptions,
              readingSessionOptions
            )
          }
        )

      default:
        const errorMessage = "Unsupported file format"
        throw Error(errorMessage)
    }
  }

  async loadPublicationFromUrl(readingSystem, url) {
    if (url.endsWith(".epub") || url.endsWith("/")) {
      return ReadingSystemAPI.getEpubPublicationFromUrl(url).then(
        (publication) => {
          const readerPublicationOptions = {
            contentProcessingOptions: {
              skipUnusedImagesInScriptedPublicationCss: true,
            },
            enableMediaStreaming: true,
            remoteResourcesNonScriptedDocumentsOptions: {
              policyType: EpubRemoteResourcePolicyType.ALLOW_ALL,
            },
          }
          const readingSessionOptions = {
            publicationToken: "n/a",
            userToken: "n/a",
          }
          return readingSystem.loadPublication(
            publication,
            readerPublicationOptions,
            readingSessionOptions
          )
        }
      )
    } else {
      return ReadingSystemAPI.getPdfPublicationFromUrl(url).then(
        (publication) => {
          const readerPublicationOptions = {}
          const readingSessionOptions = {
            publicationToken: "n/a",
            userToken: "n/a",
          }
          return readingSystem.loadPublication(
            publication,
            readerPublicationOptions,
            readingSessionOptions
          )
        }
      )
    }
  }
}

export default ReadingSystemAPI
