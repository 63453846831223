import Colors from "./Colors"

export const frecuencyList = [
  { value: "ONCE_MONTH", label: "Once a month" },
  { value: "ONCE_WEEK", label: "Once a week" },
  { value: "TWICE_WEEK", label: "Twice a week" },
  { value: "ONCE_15_DAYS", label: "Once every 15 days" },
]

export const nameOfDaysShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const nameOfDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

export const eventTypes = {
  exam: {
    title: "Fluency Assessment",
    color: Colors.PURPLE,
    enum: 0,
  },
  reading: {
    title: "Reading Practice",
    color: Colors.ORANGE,
    enum: 1,
  },
  practice: {
    title: "Practice",
    color: Colors.LIGHTBLUE,
    enum: 2,
  },
  decoding: {
    title: "Decoding Assessment",
    color: Colors.LIGHTBLUE,
    enum: 3,
  },
  "leveling-decoding": {
    title: "Leveling Decoding Assessment",
    color: Colors.PINK,
    enum: 4,
  },
  vocabulary: {
    title: "Vocabulary Session",
    color: Colors.YELLOW,
    enum: 5,
  },
  conversation: {
    title: "Conversation",
    color: Colors.RED,
    enum: 6,
  },
}

export const studentTrack = {
  "-1": { color: Colors.RED, label: "Below" },
  0: { color: Colors.LIGHTBLUE, label: "On track" },
  1: { color: Colors.GREEN, label: "Above" },
}

export const metricsTrack = {
  "-1": Colors.RED,
  0: Colors.LIGHTBLUE,
  1: Colors.GREEN,
}

export const miscues = {
  VISUAL_INITIAL: {
    color: "#40D78F",
    label: "Visual - initial",
    tooltip:
      "The student has confused a word with a word that starts with similar letters at the beginning of the word. For example, house for howl.",
  },
  VISUAL_END: {
    color: "#4579FF",
    label: "Visual - end",
    tooltip:
      "The student has confused a word with a word with similar letters at the end of the word. For example, bring for ring.",
  },
  MEANING: {
    color: "#FFE34F",
    label: "Meaning",
    tooltip: undefined,
  },
  FUNCTION_WORD: {
    color: "#00DECF",
    label: "Function words",
    tooltip:
      "The student made a mistake on a word whose purpose is more to signal grammatical relationship than the lexical meaning of a sentence, e.g., do in do you live here?. Function words include pronouns, determiners, and conjunctions.",
  },
  OMITTED: {
    color: "#FF4D97",
    label: "Omitted",
    tooltip: "The student has omitted a word.",
  },
  ENDING: {
    color: "#FFA134",
    label: "Ending",
    tooltip: undefined,
  },
  SELF_CORRECTED: {
    color: "#4d31b0",
    label: "Self corrected",
    tooltip: "The student has self-corrected a miscue.",
  },
  UNKNOWN_WORD: {
    color: "#6dff88",
    label: "Unknown word",
    tooltip: "The student seems to not have this word on their vocabulary.",
  },
  SKIPPED_SENTENCE: {
    color: "#DE00CF",
    label: "Skipped sentence",
    tooltip: "Skipped sentence: the student skipped consecutive words.",
  },
}

export const reviewImages = [
  require("../assets/img/review/Review0.png"),
  require("../assets/img/review/Review1.png"),
  require("../assets/img/review/Review2.png"),
  require("../assets/img/review/Review3.png"),
]

export const filterSortByOptions = {
  LEXILE: "Lexile Score",
  // WCPM: "WCPM",
}

export const lastAssessmentFilterOptions = [
  { key: "days", label: "3 days" },
  { key: "week", label: "a week ago" },
  { key: "weeks", label: "week+ ago" },
]

export const filteredTagsTexts = {
  lastAssessment: {
    days: { label: "3 days" },
    week: { label: "a week ago" },
    weeks: { label: "week+ ago" },
  },
  grade: studentTrack,
  status: {
    0: { label: "Pending" },
    1: { label: "Done" },
  },
  type: {
    reading: { label: "Reading Practice", color: Colors.ORANGE },
    exam: { label: "Fluency Assessment", color: Colors.PURPLE },
  },
}

export const genderLabels = {
  F: "Female",
  M: "Male",
  X: "I'd rather not say",
}

export const metrics = {
  g: {
    label: "Grade Level",
    color: Colors.PURPLE,
    chartTitle: "Grade Level",
  },
  r: {
    label: "Total Books Read",
    color: Colors.GREEN,
    chartTitle: "Total Books",
  },
  l: {
    label: "Median Assessment",
    color: Colors.LIGHTBLUE,
    chartTitle: "Assessments Lexile",
  },
}

export const readingMetricsOptionData = [
  { label: "Total Books", value: true },
  { label: "Median Books", value: false },
]

export const lvlImages = [
  require("../assets/img/achievements/lvlLocked.png"),
  require("../assets/img/achievements/lvl1.png"),
  require("../assets/img/achievements/lvl2.png"),
  require("../assets/img/achievements/lvl3.png"),
  require("../assets/img/achievements/lvl4.png"),
  require("../assets/img/achievements/lvl5.png"),
  require("../assets/img/achievements/lvl6.png"),
  require("../assets/img/achievements/lvl7.png"),
  require("../assets/img/achievements/lvl8.png"),
  require("../assets/img/achievements/lvl9.png"),
]

export const nameOfMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

// export const badgesInformation = {
//   idStreak: {
//     color: materialTheme.COLORS.ORANGE,
//     image: 'BadgeStreak',
//   },
//   idMindShaper: {
//     color: materialTheme.COLORS.PURPLE,
//     image: 'BadgeMindShaper',
//   },
// };

export const exercisesName = {
  decoding: "Decoding Assessment",
  visual: "Visual",
  pa: "Phonemic Awareness",
  auditory: "Auditory",
  sentence: "Connected Text",
}

const secureImg = (file) => file.default || file

export const booksCategoriesImages = {
  // Fiction: require("../assets/img/categories/Fiction.png").default,
  Animals: secureImg(require("../assets/img/categories/Animals.png")),
  Arts: secureImg(require("../assets/img/categories/Arts.png")),
  audiobooks: secureImg(require("../assets/img/categories/audiobooks.png")),
  Classics: secureImg(require("../assets/img/categories/Classics.png")),
  Dinosaurs: secureImg(require("../assets/img/categories/Dinosaurs.png")),
  Fiction: secureImg(require("../assets/img/categories/Fiction.png")),
  "Fun!": secureImg(require("../assets/img/categories/Fun.png")),
  Music: secureImg(require("../assets/img/categories/Music.png")),
  Nature: secureImg(require("../assets/img/categories/Nature.png")),
  phonics: secureImg(require("../assets/img/categories/Phonics.png")),
  Scary: secureImg(require("../assets/img/categories/Scary.png")),
  Science: secureImg(require("../assets/img/categories/Science.png")),
  Space: secureImg(require("../assets/img/categories/Space.png")),
  Sports: secureImg(require("../assets/img/categories/Sports.png")),
  Technology: secureImg(require("../assets/img/categories/Technology.png")),
  USA: secureImg(require("../assets/img/categories/USA.png")),
  Us: secureImg(require("../assets/img/categories/Us.png")),
  World: secureImg(require("../assets/img/categories/World.png")),
}

export const langs = [
  { value: "eng", label: "English" },
  { value: "esp", label: "Spanish" },
]

const MIN_WRITING_GRADE_ORDER = 16 // 5th beginner
// const MIN_WRITING_GRADE_NAME = "Beg-5th" // 6to en curricula normal

export const canClassUseWritingFeature = (teacherClass) => {
  const whitelistedProdClasses = [
    "66c8ce03a0e3e766f90b792a", // 6th C - Niza
    "66c8cdfea0e3e766f90b750b", // 6th B - Niza
    "66c8cdeda0e3e766f90b69fa", // 5th C - Niza
    "67922ce0b78c0860c32cc70b", // 7th A - Niza
  ]
  return (
    teacherClass?.grade?.order >= MIN_WRITING_GRADE_ORDER ||
    whitelistedProdClasses.includes(teacherClass?.id)
  )
}

export const GRADE_ORDER_EQUIVALENCIES = {
  A1: [1, 2, 3, 4, 5, 6],
  "A1.2": [7, 8, 9, 10, 11, 12],
  A2: [13, 14, 15, 16, 17, 18],
  "A2.2": [19, 20, 21, 22, 23, 24],
  B1: [25, 26, 27, 28, 29],
  "B1.2": [30, 31, 32],
  B2: [33],
}

export const CEFRLevelByGradeOrder = Object.entries(
  GRADE_ORDER_EQUIVALENCIES
).reduce((acc, [level, grades]) => {
  grades.forEach((grade) => {
    acc[grade] = level
  })
  return acc
}, {})

const MAX_PRACTICE_GRADE_ORDER = 17

export const canClassUsePracticeFeature = (teacherClass) => {
  return (
    teacherClass?.grade?.order < MAX_PRACTICE_GRADE_ORDER
  )
}